import React, {useState} from 'react'
import styled from 'styled-components'
import jsonp from 'jsonp'
import {Link} from 'react-router-dom'

import { ReactComponent as Facebook } from '../socials/facebook.svg'
import { ReactComponent as Instagram } from '../socials/instagram.svg'
import { ReactComponent as LinkedIn } from '../socials/linkedin.svg'
import { ReactComponent as Twitter } from '../socials/twitter.svg'

const Footer = () => {

   const [email, setEmail] = useState('');
   const [subscribe, setSubscribe] = useState(true)

   const subscribeButton = () =>{
      alert('Subscribed')
   }

  const onSubmit = e => {
    e.preventDefault();
    const url = 'https://crossglobalindia.us20.list-manage.com/subscribe/post-json?u=c3079b479474dddcbf3608cf7&amp;id=af2eeb69f2&amp;f_id=006e42eaf0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form';
    jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {

        console.log('data', data);
        const { msg } = data
        alert(msg);
    });
};

  return (
    <Container>


        <Main>
          <Logo>
            <Link to='/'><img src="./images/Cglogowhite.png" alt="" /></Link>
        </Logo>

        <Content>

          <Nav>

            <div>
              <ul>
                <Link to='/registrationdetail'><li>Registration Details</li></Link>
                <Link to ='ourproject'><li>Projects</li></Link>
                <Link to ='/meettheteam'><li>Meet the team</li></Link>
                <Link to='/financial'><li>Financials</li></Link>
              </ul>
            </div>
            

            <div>
              <ul>
                <li><a target='blank' href="https://open.spotify.com/show/02gJD7JX46qN5SHVp5Jwit">Kural podcast</a></li>
                {/* <li><a target='blank' href="./pdf//Cross Global Annual Report - 2021.pdf"> Annual reports</a></li> */}
                <Link to='/annualreports'><li>Annual reports</li></Link>
                <li><a target='blank' href="https://www.youtube.com/watch?v=Y7_-3RRAJpM">Kamal’s TEDx Talk</a></li>

                {/* <li>COVID’19</li> */}
              </ul>
            </div>

            <div>
              <ul>
                <li>Chennai, india</li>
                <li>+91 95001 79125</li>
                <li>contact@crossglobalindia.org</li>
                <li><Link to='/volunteer' className='meetTheTeam'>Volunteer</Link></li>
              </ul>
            </div>

          </Nav>

          <MainTwo>
            
          <Socials>
              <p className='sub'>SOCIALS</p>

              <div className='svg-container'>

                  <a href='https://www.instagram.com/crossglobal/?hl=en' target='_blank' className="svg-component">
                      <Instagram />
                  </a>
                  <a href='https://www.facebook.com/CrossGlobalIndia/' target='_blank' className="svg-component">
                      <Facebook />
                  </a>
                  <a href='https://twitter.com/CrossglobalInd' target='_blank' className="svg-component">
                      <Twitter />
                  </a>
                  <a href='https://www.linkedin.com/company/crossglobal/' target='_blank' className="svg-component">
                      <LinkedIn />
                  </a>
              </div>
          </Socials>

          <NewLetters>
            <div> 

              <form action="https://formspree.io/f/mknlkygd" method="POST">
                <input type="New Subscriber" name='E-mail' placeholder="Your e-mail" required />
                {/* <br /> */}
                <button onClick={() => setSubscribe(false)} type='submit'>{ subscribe ? 'Subscribe': 'Subscribed'}</button>

              </form>

            </div>
          </NewLetters>

          </MainTwo>

          <Registration>
            <p>Terms and condition</p>
            <p>Copyright © 2024 Cross Global Asia Foundation</p>
          </Registration>

          </Content>

        </Main>

    </Container>
  )
}

export default Footer

const MainTwo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width:650px) {
    flex-direction :column;
    align-items: start;
  }
`

const NewLetters = styled.div`

form{

        padding: 1rem 0;

        @media (max-width:650px) {
          padding: 3rem 0;
        }
                
        input{
            /* width: 100%; */
            width: 300px;
            border: none;
            padding: 1rem 1.5rem;
            margin-bottom: 1.1rem;
            border-radius: 6px;

            @media (max-width:350px) {
              width: 250px;
            }

            &:focus{
                outline: none;
            }
        }

        button{

          margin: 0 1rem;
          border: none;
          padding: 1rem 1.5rem;
          border-radius: 10px;
          color: #131313;
          background-color: var(--yellow-color);
          cursor: pointer;

          @media (max-width:650px) {
            margin: 0;
          }
        }
    }
  
`

const Container = styled.div`
    height: 60%;
    background-color: #1E1E1E;
    display: flex;
    /* align-items: center; */
    padding: 5rem 2rem;
    padding-bottom: 2rem;
    margin-top: 2rem;
`

const Main = styled.div`
  display: flex;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;

  @media (max-width:1050px) {
    flex-direction: column;
    gap: 40px;
  }
`

const Logo = styled.div`

  width: 40%;

  @media (max-width:1200px) {
    width: 30%;
  }

   img{
        width: 86px;

        @media (max-width: 500px) {
            width: 66px;
            padding: .5rem 0;
        }
      }
`

const Content = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;

    @media (max-width:1200px) {
     width: 70%;
   }

   
  @media (max-width:1050px) {
    width: 100%;
  }
    
`

const Nav = styled.div`
    display: flex;
    gap: 10%;

    @media (max-width:700px) {
      flex-direction: column;
      gap: 30px;
    }

    ul{
      li{
        color: white;
        padding: .5rem 0;
        font-size: clamp(1rem, 2vw, 1.1rem);
        a{
          
          color: white;

          &:hover{
            color: #fdcb4f;
          }

        }
        &:hover{
            color: #fdcb4f;
        }
      }
    }
`

const Socials = styled.div`
padding: 4rem 0;

    @media (max-width:650px) {
        padding: 2rem 0;
    }

    .sub{
          color: #ffffff53;
          margin-bottom: 2rem;
      }

    .svg-container{

        display: flex;
        gap: 30px;

        .svg-component svg{
            width: 35px;
            fill: white;
            display: flex;
            transition: 0.2s all ease-in-out;

            &:hover{
                fill: #FDCB4A;
            }
        }
        

        
    }
    
`

const Registration = styled.div`

    display: flex;
    gap: 10%;

    @media (max-width:490px) {
      flex-direction: column;
      gap: 30px;
    }

    p{
      color: white;
      opacity: .3;
    }
`

